import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Porz: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Porz,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Porz"
          d="M828.6,907.8l1.4,4.2l-5.5,9.1L798,892l-16-2c0.6-1.8,1.6-4.9,2.6-8c1.6-5.2,2.1-10.7,1.3-16.1l0-0.2 c-0.6-4.5-1.8-8.8-3.5-12.9l0,0c-1.6-3.8-3.7-7.4-6.3-10.7L768,832c0.2-0.3,12-13.9,12.1-14.2l9.9,8.2l8-4l4,16l24-2l8,18l-10,40 L828.6,907.8z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 797.258 865.9671)"
        >
          Porz
        </text>
      </g>
    </>
  );
});
